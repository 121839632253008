class Analysis {
  constructor({ container, analysis }) {
    if (!container) return
    this.container = container
    this.container.classList.add('j-da-event-box')

    this.traceId = gbExposeTraceid && typeof gbExposeTraceid == 'function' && gbExposeTraceid('getComponent', { componentName: 'recommend' }) || ''

    if (analysis) {
      this.init(analysis)
    }
  }

  init(analysis) {
    // ccc 埋点才需要传入
    const { spm, tabList } = analysis
    let attrs = {}
    const cccRecAttrs = {
      'data-spm': spm,                      // 自有埋点的 spm
      'data-tab-list': tabList,          // 自有埋点 tablist
    }
    attrs = Object.assign(attrs, cccRecAttrs)
    this.setContainerAttr(attrs)
  }

  setContainerAttr(attrs) {
    if (!this.container) return
    for (const key in attrs) {
      const value = attrs[key]
      if (!value) continue
      this.container.setAttribute(key, value)
    }
  }

  setAnalysisInfo({ analysisInfo, activeTab, config }) {
    const { code, poskey, recommendTitle, cccRecommend } = config
    const { 
      code: backupCode, 
      poskey: backupPoskey, 
      recommendTitle: backupTitle,
      refresh,
      style = 'detail', isFaultTolerant, dataType, activeFrom, sceneId, location, tabList
    } = analysisInfo

    // 公共属性
    let attrs = {
      code: code || backupCode,
      'data-module': 'module',
      'data-poskey': poskey || backupPoskey,
      'data-is-fault-tolerant': Number(isFaultTolerant), // 推荐位(商品)的数据是否是容错的数据
      'data-style': style,      // 商品点击是弹窗还是商详 popup/detail
      'data-scene': sceneId,    // 当前商品列表的请求场景是什么?
      'data-active-from': activeFrom,           // 自有埋点，推荐位来自哪里，跟推荐位名称有点类似
      'data-refresh': refresh,  // 商品是否是刷新之后的
      'data-location': location,
    }

    const { sku_cate_id, sku_cate_nm, index = 1 } = activeTab
    if (sku_cate_nm) {
      attrs['data-tab-list'] = tabList
        || `${index}\`${sku_cate_id || '-'}\`-\`${sku_cate_nm}`
    }

    if (cccRecommend) {
      const { ruleId, pageId, floor, comId } = config
      const cccRecAttrs = {
        'data-component-type': 'cccRecommend',
        'data-rule-id': ruleId,
        'data-page-id': pageId,
        'data-floor-id': floor,
        'data-com-id': comId,                 // 自动化推荐组件的组件id
        'data-cate-id': sku_cate_id,          // 自动化推荐组件的分类类型
        'data-cate-name': sku_cate_nm,        // 自动化推荐组件的分类名称
      }
      attrs = Object.assign(attrs, cccRecAttrs)
    } else {
      const normalRecAttrs = {
        'data-dataType': dataType,                // 推荐位商品的数据来源
        'data-title': recommendTitle || backupTitle,             // 推荐位名称 埋点上报，ga用到
        'data-traceid': this.traceId,             // traceid
      }
      attrs = Object.assign(attrs, normalRecAttrs)
    }

    this.setContainerAttr(attrs)
  }
}

export default Analysis
