import { abtservice } from 'public/src/services/abt'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getSceneMaterialConfig } from 'public/src/services/api/ccc.js'

/**
 * 获取图鉴场景的橱窗配置
 */
export const getRecMaterialConfig = async ({ rowNum = 2, material = [], pageKey = '', displayWindowAbtBranch = '' }) => {
  // 没有abt 不做任何请求
  if (!material || !material.length) return {}
  const materialTypes = material.filter(_ => !!_.materialDataType)
  if (!materialTypes.length) return {}

  const meterialResult = await getSceneMaterialConfig({
    pageKey,
    subPageKey: rowNum == 2 ? 'other_recommend_2pic' : 'other_recommend_3pic', // TODO
    materialTypes,
    displayWindowAbtBranch
  })

  return {
    goodsNameLine: meterialResult?.info.cccConfig?.mobileVerticalView?.goodsNameLine,
    addBagBtn: !!meterialResult?.info.cccConfig?.mobileVerticalView?.addBagBtn
  }
}

export const getRecAbtResult = async ({
  poskeys, newPoskeys,
  showFeedbackPoskey,
  itemColorStylePoskey = '',
}) => {
  const feedbackPoskey = showFeedbackPoskey || 'RecommendExpandPop'
  const posKeys = ['SellingPoint', 'AllListQuickShip', feedbackPoskey, poskeys].filter(i => !!i).join()
  const newPosKeys = [itemColorStylePoskey, newPoskeys, 'detailonetwoTitle'].filter(i => !!i).join()
  const abtInfo = await getUserAbtData() // 推荐场景的abt

  // eslint-disable-next-line @shein-aidc/abt/abt
  const r = await abtservice.getUserAbtResult({ posKeys, newPosKeys })
  return {
    abtResult: r,
    showSellingPoint: r?.['SellingPoint']?.param == 'type=sellingpoint',
    showFeedback: r?.[feedbackPoskey]?.param == 'pop',
    showQuickShip: r?.['AllListQuickShip']?.param  == 'type=B',
    showNewDiscountLabel: abtInfo?.['discountLabel']?.param?.['discountLabel'] == 'Label',
    showGreySellingPoint: abtInfo?.['greysellingPoint']?.param?.['greysellingPoint'] == 'grey',
    showAddBagAfterGoodsPrice: abtInfo?.['addtobagDetail']?.param?.['addtobagDetail'] == 'bagDetail', // 加车按钮调整到价格之后
    canIChangeColorStyle: itemColorStylePoskey ? r?.[itemColorStylePoskey]?.param?.['colorDetail'] == 'Color' : false, // 色块样式变更
    waterFallStyle: abtInfo?.RecoLoadmore.param?.RecoLoadmore === 'type=waterfall',
    onetwoTitleParams: abtInfo?.detailonetwoTitle?.param?.detailonetwoTitle,
  }
}
