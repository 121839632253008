export default {
  data () {
    return {
      constantData: {}
    }
  },
  created () {
    const {
      LAZY_IMG,
      LAZY_IMG_PIXEL,
      IMG_LINK,
      LAZY_IMG_SOLID_COLOR,
      GB_cssRight, 
      IS_RW,
      langPath,
      PUBLIC_CDN,
      lang,
      currency,
      currencies,
      appLanguage
    } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo
    
    this.constantData = Object.assign({}, this.constantData, { LAZY_IMG, LAZY_IMG_PIXEL, IMG_LINK, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW, langPath, PUBLIC_CDN, lang, currency, currencies, appLanguage })
  },
}
