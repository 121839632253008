import { transformImg, preloadImgs } from '@shein/common-function'

export const getStarVisible = ({
  showStarRating = false,
  showStarComment = false,
  isListstarB = false,
}, starComment) => {
  const flag = starComment?.comment_num >= 100
  const hasStarRating = showStarRating && !!starComment
  const hasStarComment = starComment ?
    (showStarComment ? (isListstarB ? true : flag) : false) :
    false

  return {
    hasStarRating,
    hasStarComment,
  }
}

export const addProductDetailBfCache = (item, lazyLoadSrc = '', showSpuImg = false) => {
  try {
    // 新增的取spu图的缓存逻辑
    const firstSpuImg = (item.spu_image || [])[0]?.medium_image || ''
    const secondSpuImg = (item.spu_image || [])[1]?.medium_image || ''
    // 是否裁切过
    const isCut = checkIsCut(lazyLoadSrc)
    // 二图取值及尺寸处理
    const secondDetailImage = showSpuImg && secondSpuImg ? { 'origin_image': secondSpuImg } : ((item.detail_image || [])[0] || {})
    const secondDetailImageUrl = transformSecondDetailImage(secondDetailImage?.origin_image || secondDetailImage || '', 336)
    const data = {
      goods_img: transformImg({ img: showSpuImg && firstSpuImg ? firstSpuImg : item.goods_img }),
      goods_lazy_img: isCut ? lazyLoadSrc : '',
      goods_name: item.goods_name,
      salePrice: item.salePrice,
      retailPrice: item.retailPrice,
      unit_discount: item.unit_discount,
      relatedColor: item.relatedColor,
      // 传给详情页的详细图, 暂时只传二图 (主图为 goods_img), 其他 3/4/5... 图不传, 详情页自己会去获取
      // 传二图的目的是让详情页的首页快速展示两张图
      detail_image: [{
        origin_image: secondDetailImageUrl,
      }],
    }
    if (item.commentInfo) {
      data.comment = {
        comment_rank: item.commentInfo.comment_rank_average,
        comment_num: item.commentInfo.comment_num
      }
    }

    // 有二图则提前预加载二图 (GIF图不预加载)
    if (secondDetailImageUrl && !checkIsGif(secondDetailImageUrl)) {
      preloadImgs({ imgs: [secondDetailImageUrl] })
    }
    sessionStorage.setItem('productDetailBfCache', JSON.stringify(data))
  } catch (error) {
    console.log(error)
  }
}

/**
 * 腰带与价格之间的控制逻辑
 * @param {Object} config abt相关的配置
 * @param {Object} param1 
 * @returns {{
 *  beltLabel: false | Object;
 *  showBestDeal: boolean;
 *  followLabelVisible: boolean;
 *  isPromoPrice: boolean;
 * }}
 */
export const controlBeltPrice = (config, {
  isSoldOut = false,
  pretreatInfo,
  viewsKey = 'mobileVerticalView',
}) => {
  const { showBeltLabel } = config || {}
  const beltLabel = showBeltLabel && !isSoldOut && pretreatInfo?.[viewsKey]?.beltLabel // ccc配置腰带
  
  const showBestDeal = pretreatInfo.showBestDeal // 保留款腰带
  let followLabelVisible = pretreatInfo.followLabelVisible && !isSoldOut // 跟价款腰带
  let isPromoPrice = false // 价格是否展示促销状态

  if (beltLabel || showBestDeal) {
    // 保留款腰带>CCC配置的促销腰带>跟价款腰带
    followLabelVisible = false
  }
  if (followLabelVisible || showBestDeal) {
    isPromoPrice = true
  }

  return {
    beltLabel,
    showBestDeal,
    followLabelVisible,
    isPromoPrice,
  }
}

/**
 * 传入价格字符串,返回价格的整数(numberBeforeDot)、小数部分(numberAfterDot)、前后的币种符号(currencySymbolPrefix、currencySymbolSuffix)
 * @param {*} originString 
 * @returns 
 */
export const handlePriceHtml = (originString) => {
  // 货币符号在前
  const currencySymbolPrefix = originString?.match(/(^\D+)/)?.[0] || ''
  // 货币符号在后
  const currencySymbolSuffix = originString?.match(/(\D+)$/)?.[0] || ''
  // 去除货币符号的价格
  const priceWithoutSymbol =
    originString
      ?.replace(currencySymbolPrefix, '')
      ?.replace(currencySymbolSuffix, '') || ''
  // 价格是否有小数
  const isDecimal = !!priceWithoutSymbol?.includes('.')
  // 价格小数部分
  const numberAfterDot = isDecimal ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || '' : ''
  // 价格整数部分
  const numberBeforeDot = priceWithoutSymbol.replace(numberAfterDot, '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix }
}

/**
 * 相比于handlePriceHtml，handleCamelCasePrice会将可能包含的数字分隔符作为小数点进行处理（即分隔符前作为整数，分隔符后作为小数部分）
 * 传入价格字符串,返回价格的整数(numberBeforeDot)、小数部分(numberAfterDot)、前后的币种符号(currencySymbolPrefix、currencySymbolSuffix)
 * @param {*} originString 
 * @returns 
 */
export const handleCamelCasePrice = (price = {}) => {
  const { amountWithSymbol, amount } = price
  // 货币符号在前
  const currencySymbolPrefix = amountWithSymbol?.match(/(^\D+)/)?.[0] || ''
  // 货币符号在后
  const currencySymbolSuffix = amountWithSymbol?.match(/(\D+)$/)?.[0] || ''
  // 去除货币符号的价格
  const priceWithoutSymbol =
    amountWithSymbol
      ?.replace(currencySymbolPrefix, '')
      ?.replace(currencySymbolSuffix, '') || ''
  // 价格是否有小数
  const isDecimal = !!amount?.includes('.')
  // 价格小数部分
  const numberAfterDot = isDecimal ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || '' : ''
  // 价格整数部分
  const numberBeforeDot = priceWithoutSymbol.replace(numberAfterDot, '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix }
}

/** 二图处理 */
const transformSecondDetailImage = (url, cutSize) => {
  let transfromUrl = url || ''

  if (!transfromUrl || typeof transfromUrl !== 'string') return ''
  
  // 如果没有携带尺寸信息则直接补充
  if (transfromUrl && transfromUrl?.indexOf('_thumbnail_') === -1) {
    return transformImg({ img: transfromUrl?.replace('.jpg', `_thumbnail_${cutSize}x.jpg`) })
  }

  // 转webp
  if (transfromUrl) {
    transfromUrl = transformImg({ img: url })
  }

  // 二图裁切
  return transfromUrl?.replace(/(\d+)x(\d*)(\.)(webp|png|jpg)$/, (match) => {
    const [, suffix] = match.split('.')
    return `${cutSize}x.${suffix}`
  })
}

/** 判断是否为gif图 */
const checkIsGif = (imgUrl = '') => {
  return /\b(\.gif\b)/.test(imgUrl)
}

/** 判断是否lazy (仅405或336尺寸的裁切图可直接运用于商详,其他太小的尺寸传到商详会比较模糊) */
const checkIsCut = (url) => {
  if (!url) return false
  return url.indexOf('_thumbnail_405') !== -1 || url.indexOf('_thumbnail_336') !== -1
}

/**
 * 是否是专题页
 * @returns {boolean}
 */
export const isActivityPage = () => {
  return gbCommonInfo?.tabActive === 'activity'
}

/**
 * 是否是闪购页
 * @returns {boolean}
 */
export const isFlashSalePage = () => {
  return gbCommonInfo?.tabActive === 'flash_sale'
}
