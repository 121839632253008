import { FetchData } from 'public/src/services/productRecommend/fetchData.js'
import emarsys from 'public/src/services/productRecommend/emarsys/index.js'
import { logicMap } from 'public/src/services/productRecommend/emarsys/config.js'
/**
 * 只做数据请求处理
 */
export default class RecommendService {
  /**
   * 
   * @param {*} param0 
   */
  constructor () {
    this.fetchData = new FetchData()
  }

  /**
   * 通过实验匹配推荐类型
   * @param {*} param0 
   * @returns requestInfo
   * {
      type: 'request',
      matchRule: /^is_pde=3/,
      url: `/product/recommend/facade`,
      params: {
        pageEnable: 0,
        scene_id: 19,
        rule_id: 20,
        goodsList: ''
      },
      query: {
        pageNum: 1,
        limit: 5,
        reqNum: 5,
      }
   * }
   */
  getMatchConfig(abtInfo, matchRules) {
    if (abtInfo && /^emarsys/.test(abtInfo.param)) {
      const em = abtInfo.param.split('_')
      const emarsysType = em[1] || ''
      if (['related', 'cart'].includes(emarsysType)) {
        emarsys().logicsCommand(emarsysType)
      }
    }
    // 1. 通过实验结果来匹配配置的结果
    const matchType = ['emarsys', 'request', 'none', 'requestForGroup']
    const hasAbtResult = abtInfo instanceof Object && Object.prototype.hasOwnProperty.call(abtInfo, 'param')
    let requestInfo = null

    // 1.1 实验没配置返回
    if (!hasAbtResult) return null

    for (let config of matchRules) {
      if (!matchType.includes(config.type)) continue
      if (config.matchRule instanceof RegExp) {
        // match abt result
        if (config.matchRule.test(abtInfo.param)) {
          requestInfo = config
          // Take the params from the abtInfo if the requestInfo.type is emarsys
          // 如果是emarsys推荐，请求的类型参数即为实验配置的参数
          if (requestInfo.type === 'emarsys') requestInfo['params'] = abtInfo.param
          break
        }
      } else if (config.matchRule instanceof Function) {
        if (config.matchRule({ abtResult: abtInfo || {} })) {
          requestInfo = config
        }
      }
    }

    // 1.2 请求的链接参数如果是个函数要返回一个请求的地址
    // get url by fun
    if (requestInfo && requestInfo.url instanceof Function) {
      requestInfo.url = requestInfo.url({ abtResult: abtInfo || {} })
    }

    // 1.3 请求的参数如果是个函数 执行函数返回真实请求的参数
    // get params by fun
    if (requestInfo && requestInfo.params instanceof Function) {
      requestInfo.params = requestInfo.params({ abtResult: abtInfo || {} })
    }

    // 1.4 对请求参数值进行校正
    // get params field by reg or fun
    Object.keys(requestInfo && requestInfo.params || {}).forEach((item) => {
      const value = requestInfo.params[item]
      // 1.4.1 如果值是正则
      // deal regexp
      if (hasAbtResult && value instanceof RegExp && typeof abtInfo.param == 'string') {
        // 从实验参数中获取结果值
        let matchResult = abtInfo.param.match(value)
        requestInfo.params[item] = matchResult && matchResult.length >= 2 ? (matchResult[1] || '') : ''
      }

      // 1.4.2 如果值是个函数
      // deal fun
      if (value instanceof Function) {
        let argValue = value({ abtResult: abtInfo || {} })
        requestInfo.params[item] = argValue
      }
    })

    return requestInfo
  }


  /**
   * @param {*} params 请求的配置
   * {
   *    type: 'request',
   *    params: {},
   *    url: ''
   * }
   * @param {*} param1 请求扩展参数
   * {
   * pageKey
   * rowNum
   * }
   */
  async fetchRecommendData({ request = null, backupRequest = null } = {}, extendParams = {}) {
    let recommendInfo = null
    const { pageNum = 1 } = extendParams

    try {
      //1. 请求配置
      recommendInfo = await this.requestService(request, extendParams)

      //2. 是否要走容错判断
      let limitLength = backupRequest && backupRequest.triggerLimit || 10
      if (backupRequest && (
        !request || (!recommendInfo || !recommendInfo.products || recommendInfo.products.length < limitLength) && pageNum === 1
      )) { 
        // 2.1 容错原因上报
        let faultReason = {
          abtId: '',
          reason: ''
        }
        let reasons = [recommendInfo && recommendInfo.reason || '']
        const sourceAbtInfo = backupRequest.sourceAbtInfo

        if (!request && !sourceAbtInfo) {
          reasons.push('无实验')
        } else {
          // 1. 先判断实验
          if (sourceAbtInfo) {
            faultReason.abtId = sourceAbtInfo.expid
            reasons.push(sourceAbtInfo.param ? '实验参数非空' : '实验参数为空')
          }

          // 2. 再判断数量
          if (recommendInfo && recommendInfo.products && recommendInfo.products.length < limitLength) {
            reasons.push('推荐结果返回数量不足')
          }
        }

        faultReason.reason = reasons.filter(i => !!i).join(';')

        // 容错没有分页默认请求100条数据
        recommendInfo = await this.requestService(backupRequest, { ...extendParams, limit: backupRequest?.limit || 100, faultReason })
        recommendInfo.isFaultTolerant = 1
      }
    } catch (error) {
      console.log(error)
      recommendInfo.error = 1
      if (backupRequest) {
        const faultReason = {
          abtId: '',
          reason: '请求错误或超时'
        }

        recommendInfo = await this.requestService(backupRequest, { ...extendParams, faultReason })
        recommendInfo.isFaultTolerant = 1
      }
    }

    return {
      recommendInfo,
    }
  }

  async requestService(config, {
    pageKey = '',
    pageNum = 1,
    rowNum = 2,
    limitNum = 100,
    limit = 100,
    itemConfig = {},
    faultReason = null
  } = {}) {
    let recommendInfo = {}
    const { url, type, params, timeout = 10000, dataType = 'own' } = config || {}
    config && config.beforeSendHandler && config.beforeSendHandler()
    const method = config.method || 'GET'
    const withAtomic = !faultReason || config.atomic

    switch(type) {
      case 'emarsys':
        // emarsys没有分页，请求数据时limit取limitNum总数
        recommendInfo = await this.fetchData.getEmarsysData({ type: method, itemConfig, pageKey, rowNum, logic: logicMap.get(params), timeout: timeout, limit: limitNum <= 100 ? limitNum : 100 })
        break
      case 'request': 
        {
          let data = params
          if (faultReason) {
            data['faultReason'] = JSON.stringify(faultReason)
          }
          recommendInfo = await this.fetchData.getOwnData({ withAtomic, type: method, itemConfig, pageKey, rowNum, url, data, timeout: timeout, dataType, pageNum, limit, limitNum })
        }
        break
      case 'requestForGroup':
        recommendInfo = await this.fetchData.fetchOwnDataForGroup({ type: method, itemConfig, pageKey, rowNum, url, data: params, timeout: timeout, dataType })
        break
      case 'none':
        recommendInfo.products = []
        break
      default:
        recommendInfo.products = []
    }

    return recommendInfo
  }

}
